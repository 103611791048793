@media(max-width: 1199px) {
    .contact {
        padding-left: 106px; }

    .page_article {
        flex-wrap: wrap; }

    .page_article__left {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px; }

    .page_article__right {
        padding-left: 0; }

    .reference_item {
        flex: 0 1 50%;
        max-width: 50%; }

    .header_burger {
        display: block;
        position: relative;
        width: 30px;
        height: 30px;
        z-index: 3;
        cursor: pointer;
        order: 3;
        margin-left: auto; }

    .header_burger span {
        position: absolute;
        width: 100%;
        left: 0;
        transition-property: all;
        height: 2px;
        transition-duration: .2s;
        transition-timing-function: ease;
        transform: translateZ(0); }

    .header_burger span:nth-of-type(1) {
        top: 5px;
        background-color: #003E84; }

    .header_burger span:nth-of-type(2), .header_burger span:nth-of-type(3) {
        top: 13px;
        background-color: #003E84; }

    .header_burger.opened span:nth-of-type(2), .header_burger.opened span:nth-of-type(3) {
        background-color: #003E84; }

    .header_burger span:nth-of-type(4) {
        top: 21px;
        background-color: #003E84; }

    .header_burger.opened span:nth-of-type(1) {
        transform: translate(0,10px) scale(.01);
        opacity: 0; }

    .header_burger.opened span:nth-of-type(2) {
        transform: rotate(45deg); }

    .header_burger.opened span:nth-of-type(3) {
        transform: rotate(-45deg); }

    .header_burger.opened span:nth-of-type(4) {
        transform: translate(0,-10px) scale(.01);
        opacity: 0; }

    .header_menu {
        display: none; }

    .header_callback {
        display: none; }

    .mobile_header {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: 110px;
        padding-top: 50px;
        padding-bottom: 100px;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background: #fff;
        transform: translateY(-500%);
        transition: transform .3s ease-in-out; }
    .header_logo img {
        position: relative;
        z-index: 5; }
    .header_sub {
        z-index: 5; }
    .mobile_header.opened {
        transform: translateY(0);
        overflow: scroll; }
    .header_logos {
        flex: 0 1 50%;
        max-width: 50%; }

    .header_info {
        flex: 0 1 45%;
        max-width: 45%; }

    .main_links__block-items_item {
        flex: 0 1 50%;
        max-width: 50%; }

    .main_links__block-items_item:nth-of-type(2) {
        border-right: 1px solid #e2e2e2; }

    .main_links__block-items_item:nth-of-type(4) {
        border-right: 1px solid #e2e2e2;
        border-left: none;
        border-bottom: none; }

    .main_reference__slider .slick-prev, .main_reference__slider .slick-next {
        top: 100%; }

    .main_reviews__slider-item {
        padding-left: 35px;
        padding-right: 35px; }

    .main_useful__block {
        margin: 0 -25px; }

    .main_useful__block-item {
        padding: 0 20px; }

    .footer_middle__nav ul:nth-of-type(2) {
        padding-left: 50px; }

    .footer_middle__right {
        padding-left: 25px; }

    .footer_bottom__copyright {
        flex: 0 1 336px;
        max-width: 336px; }

    .footer_bottom {
        align-items: center; }

    .progress-wrap {
        right: 30px;
        bottom: 30px; }
    .header_info {
        padding-right: 50px; }

    .header {
        padding: 40px 0;
        transition: padding .5s ease-in-out; }

    header.moved .header {
        padding: 12px 0; }

    .mobile_header a.popup-content {
        width: 200px;
        height: 60px;
        background-color: #003E84;
        margin: 10px auto 50px;
        font-size: 15px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center; }

    .mobile_header__menu {
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto; }

    .mobile_header__menu a {
        font-size: 20px;
        line-height: 48px;
        color: #000; }

    .mobile_header__info {
        width: 210px;
        margin: 90px auto 0; } }


@media(max-width: 991px) {
    .contact {
        padding-left: 0;
        flex-direction: column; }

    .contact_left {
        flex: 0 1 100%;
        max-width: 100%; }

    .storitve_page {
        flex-wrap: wrap; }

    .storitve_page__left {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px; }

    .storitve_page__right {
        padding-left: 0; }

    .storitve_subcat {
        margin: 0 -20px; }

    .storitve_subcat__item {
        padding-left: 20px;
        padding-right: 20px; }

    .header_info {
        flex: 0 1 35%;
        max-width: 35%; }

    .main_points__block {
        flex-wrap: wrap; }

    .main_points__block-age {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 30px;
        justify-content: center; }

    .main_points__block-item {
        flex: 0 1 50%;
        max-width: 50%; }

    .main_links__textblock {
        padding: 60px; }

    .main_links__block-items_item a:hover:before, .footer_bottom__link a:hover:before, .footer_middle__nav a:hover:before, .main_useful__block-item_title a:hover:before {
        width: 0; }

    .main_reference__slider-item_info {
        width: 300px;
        right: 50%;
        margin-right: -150px;
        padding: 40px; }

    .main_reference__block a {
        right: 50%;
        margin-right: -124px;
        top: 115%; }

    .main_reference {
        padding-bottom: 260px; }

    .main_reviews__slider-item_left__dolzh {
        padding-left: 0; }

    .main_useful__block-item p {
        margin-top: 0; }

    .footer_middle {
        flex-wrap: wrap; }

    .footer_middle__left {
        flex: 0 1 100%;
        max-width: 100%; }

    .footer_middle__right {
        flex: 0 1 100%;
        max-width: 100%; }

    .footer_middle__nav ul:nth-of-type(2) {
        padding-left: 100px; }

    .footer_middle__right {
        padding-left: 0;
        margin-top: 50px; }

    a.footer_phone {
        display: block; }

    .footer_bottom {
        flex-wrap: wrap; }

    .footer_bottom__copyright {
        flex: 0 1 100%;
        max-width: 100%;
        order: 3;
        margin-top: 30px; }
    .header_info {
        display: none; }
    .radio_block__left, .check_block__left {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 15px; } }


@media(max-width: 767px) {
    .mobile_header {
        margin-top: 54px; }
    .reference_item {
        flex: 0 1 100%;
        max-width: 100%; }

    .storitve_subcat__item {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center; }

    .page_padding {
        padding-top: 150px; }

    .header_sub p {
        display: none; }

    .tabs__caption li {
        width: 240px; }

    .main_points__block-item {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 25px; }

    .main_points__block-age {
        margin-bottom: 25px; }

    .header_sub:before {
        background-image: url(../img/icon_flag_mob.svg);
        width: 79px;
        height: 73px;
        top: -17px; }

    .header_sub span {
        font-weight: bold;
        font-size: 18px;
        padding-left: 8px; }

    .header_logo {
        order: 2;
        position: relative;
        top: 10px;
        padding-right: 0; }

    .header_sub {
        position: relative;
        left: 0; }

    .header_logos {
        flex: 0 1 56%;
        max-width: 56%;
        justify-content: space-between; }

    .header_logo img {
        width: 69px; }

    header {
        height: 54px;
        display: flex;
        align-items: center; }

    .main_slider__item {
        padding-top: 115px; }

    .main_points__block-age {
        justify-content: flex-start;
        padding-left: 25px; }

    .main_links__block-items_item {
        flex: 0 1 100%;
        max-width: 100%;
        border: 1px solid #e2e2e2!important;
        border-bottom: none!important; }

    .main_links__block-items_item:last-of-type {
        border-bottom: 1px solid #e2e2e2!important; }

    .main_about__item {
        width: 100%; }

    .main_certs__block {
        display: none; }

    .main_certs__slider {
        display: block; }

    .main_certs__slider-item {
        text-align: center; }

    .main_certs__slider .slick-prev {
        position: absolute;
        bottom: -30px;
        left: 50%;
        background-color: #003E84;
        width: 93px;
        height: 70px;
        z-index: 2;
        margin-left: -93px;
        transition: background-color .3s ease-in-out; }

    .main_certs__slider .slick-next {
        position: absolute;
        bottom: -30px;
        left: 50%;
        background-color: #003E84;
        width: 93px;
        height: 70px;
        z-index: 2;
        transition: background-color .3s ease-in-out; }

    .main_certs__slider .slick-prev:before {
        content: '';
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: -8.5px;
        width: 17px;
        height: 17px;
        border: 1.5px solid #ffffff;
        border-right: none;
        border-bottom: none;
        transform: rotate(-45deg);
        transition: right .3s ease-in-out; }

    .main_certs__slider .slick-next:before {
        content: '';
        position: absolute;
        left: 20px;
        top: 50%;
        margin-top: -8.5px;
        width: 17px;
        height: 17px;
        border: 1.5px solid #ffffff;
        border-right: none;
        border-bottom: none;
        transform: rotate(135deg);
        transition: left .3s ease-in-out; }

    .main_certs__slider .slick-next:hover, .main_reference__slider .slick-prev:hover {
        background-color: #0055B5; }

    .main_certs__slider .slick-next:hover:before {
        left: 35px; }

    .main_certs__slider .slick-prev:hover:before {
        right: 35px; }

    .main_reviews__slider-item {
        flex-wrap: wrap;
        padding: 55px; }

    .main_reviews__slider-item_left {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 30px;
        text-align: center; }

    .main_reviews__slider-item_right {
        flex: 0 1 100%;
        max-width: 100%; }

    .main_reviews__slider-item_right:before {
        left: 50%;
        top: 0;
        margin-left: -13px; }

    .main_reviews__slider-item_right {
        padding-left: 0;
        padding-top: 50px; }

    .main_reviews__slider-item_right__link {
        text-align: center; }

    .main_reviews__slider-item_left__dolzh {
        margin-bottom: 10px; }

    .main_useful__block-item {
        flex: 0 1 100%;
        max-width: 100%;
        text-align: center; }

    .main_useful__block-item p {
        padding-right: 0;
        text-align: left; }

    .footer_bottom__link {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 30px; }

    .footer_bottom__link a {
        display: block; }

    .footer_bottom__radian a p {
        order: 2;
        text-align: left;
        padding-left: 10px; }

    .footer_middle {
        margin-bottom: 50px; }
    .radio_block__item, .check_block__right-left, .check_block__right-right {
        flex: 0 1 100%;
        max-width: 100%; }

    .big_form__last p {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 30px;
        padding-right: 0; }

    button.substraction {
        margin: 0 auto; }

    .big_form label.input_label p {
        margin-top: -45px; }

    .big_form label.input_label p.fortextarea {
        margin-top: -65px; }

    .big_form label.input_label input, .big_form label.input_label textarea {
        padding: 17px 0 10px; }

    .mobile_arrow {
        display: block;
        position: absolute;
        right: 16px;
        top: 32px;
        width: 40px;
        height: 40px; }

    .mobile_arrow:before {
        content: '';
        top: 50%;
        left: 50%;
        width: 14px;
        height: 14px;
        margin-top: -7px;
        margin-left: -7px;
        border: 2px solid #003E84;
        border-left: none;
        border-bottom: none;
        transform: rotate(135deg);
        position: absolute;
        transition: transform .3s ease-in-out; }

    .mobile_arrow.active:before {
        transform: rotate(315deg); }

    .main_links__block-items_item__title {
        padding-left: 70px;
        margin-bottom: 0; }

    .main_links__block-items_item img {
        right: 0;
        left: 13px; }

    .main_links__block-items_item__links {
        max-height: 0;
        opacity: 0;
        transition: max-height .3s ease-in-out, opacity .3s ease-in-out, margin-top .3s ease-in-out; }

    .main_links__block-items_item.active .main_links__block-items_item__links {
        max-height: 100%;
        opacity: 1;
        margin-top: 30px; } }


@media(max-width: 575px) {
    .header_logos {
        flex: 0 1 60%;
        max-width: 60%; }
    body.opened header > .container {
        z-index: 1;
        box-shadow: 0 3px 45px #00000033; }

    .main_links__block-items_item img {
        width: 45px;
        height: 45px;
        top: 30px; }

    .main_slider .slick-dots:before {
        left: -74px;
        width: calc(100% + 148px); }

    .contact_block {
        flex-direction: column; }

    .reference_links {
        padding-left: 0;
        padding-right: 0; }

    .breadcrumbs {
        flex-direction: column; }

    .breadcrumbs__link {
        margin-bottom: 5px; }

    .main_slider__item-title h2 {
        font-size: 20px;
        line-height: 24px; }

    .main_slider__item-text {
        font-size: 15px;
        line-height: 20px;
        padding-right: 40px; }

    .main_slider__item-text br {
        display: none; }

    .main_slider__item-title img {
        width: 222px; }

    .main_slider__item {
        padding-top: 105px; }

    .main_slider__item-link a {
        margin: 0 auto; }

    .main_slider .slick-dots li {
        margin: 0 10px; }

    .main_slider .slick-dots {
        margin-left: -99.5px; }

    .main_slider .slick-prev {
        margin-left: -173px; }

    .main_slider .slick-next {
        margin-left: 89px; }

    h2.title, h1.title {
        font-size: 30px; }

    .subtitle {
        line-height: 22px;
        font-family: Roboto;
        padding-top: 30px; }

    .subtitle br {
        display: none; }
    .tabs__content {
        height: auto;
        transform: translateY(10px); }
    .tabs__caption {
        margin-bottom: 0; }

    .tabs__caption li {
        width: 0;
        box-shadow: none; }

    .tabs__caption li.active {
        width: 100%; }

    .main_links__textblock {
        padding: 15px; }

    .main_links__block-items_item {
        padding: 26px; }

    .main_links__block-items_item__title {
        padding-right: 30px; }

    .main_reference .container, .main_reviews .container {
        padding: 0; }

    .main_reference__slider .slick-list {
        overflow: visible; }

    .main_reference__slider-item_info {
        top: 75%; }

    .main_reference__slider .slick-prev, .main_reference__slider .slick-next {
        top: calc(100% + 234px); }

    .main_reference__block a {
        top: 145%; }

    .main_reference {
        padding-bottom: 500px; }

    .main_partners__slider-item img {
        margin: 0 auto; }

    .main_about__item {
        top: 150px;
        padding: 30px; }

    .main_about__item p {
        font-family: Roboto; }

    .main_about__item a.substraction {
        width: 100%; }

    .main_certs__slider .slick-prev, .main_certs__slider .slick-next {
        bottom: -37px; }

    .main_reviews__slider-item {
        padding: 30px 20px 40px; }

    .main_reference__block h2.title, .main_reference__block .subtitle, .main_reviews h2.title, .main_reviews .subtitle, .main_reference__block h1.title, .main_reviews h1.title {
        padding-left: 15px;
        padding-right: 15px; }

    .main_reviews__slider-item_right__text {
        font-family: Roboto; }

    .footer_top {
        text-align: center; }

    .footer_middle__nav {
        flex-direction: column; }

    .footer_middle__nav ul:nth-of-type(2) {
        padding-left: 0; }

    .footer_bottom {
        padding-bottom: 20px; }
    .header {
        padding: 0!important;
        height: 54px; }

    .form-popup {
        padding: 20px; }

    .form-popup label.input_label input {
        padding: 10px 0; }

    .form-popup label.input_label p {
        margin-top: -40px; }

    .form-title {
        margin-bottom: 70px; }

    .form-popup.active {
        transform: translateX(0); }
    .interior {
        left: 0;
        width: 100%;
        max-height: 100vh;
        overflow: auto; }
    .form-popup {
        max-height: 100vh;
        overflow: auto; } }
